<template>
  <div>
    <search-form>
      <div class="d-inline-flex flex-wrap mb-3">
        <c-input v-model="search.id" label="ID" search-form />
        <date-picker v-model="search.created.min" search-form :label="$t('shared.createdMin')" />
        <date-picker v-model="search.created.max" search-form :label="$t('shared.createdMax')" />
        <c-input v-model="search.email" :label="$t('shared.email')" search-form />
        <c-input v-model="search.mobile" :label="$t('shared.mobile')" search-form />
        <c-input select v-model="search.status" :items="statuses" :label="$t('shared.status')" search-form />
        <auto-complete
          v-model="search.organizationId"
          :search="organizationSearch"
          :label="$t('organizations.organization')"
          search-form
        />

        <auto-complete
          v-model="search.workingSiteId"
          :search="workingSiteSearch"
          :label="$t('workingSites.workingSite')"
          search-form
        />

        <c-input select v-model="search.status" :items="statuses" :label="$t('shared.status')" search-form />
      </div>

      <div class="ma-1">
        <v-btn color="success" dark @click="filtering" small :loading="progressing">
          <v-icon small class="me-1"> mdi-filter </v-icon>
          {{ $t('shared.applyFilter') }}
        </v-btn>
      </div>
    </search-form>

    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="context"
      @update:options="filtering"
      :server-items-length="count"
      :loading="progressing"
      :footer-props="$config.tableFooterProps"
      sort-by="id"
      must-sort
      class="elevation-2"
    >
      <template #[`item.actions`]="{item}">
        <div class="d-flex justify-end">
          <template v-if="item.status === 'PROCESSING'">
            <v-tooltip top>
              <template v-slot:activator="{on}">
                <v-btn small fab text color="error" v-on="on" @click="reject(item)">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('shared.reject') }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{on}">
                <v-btn small fab text color="success" v-on="on" @click="accept(item)">
                  <v-icon> mdi-check </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('shared.accept') }}</span>
            </v-tooltip>
          </template>

          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-btn small fab text color="error" @click="removeItem(item)" v-on="on">
                <v-icon> mdi-delete </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('shared.delete') }}</span>
          </v-tooltip>
        </div>
      </template>

      <template #[`item.created`]="{item}">
        <date-display :value="item.created" />
      </template>

      <template #[`item.organizationInfo`]="{item}">
        <span v-if="item.organizationInfo">{{ item.organizationInfo.name }}</span>
      </template>

      <template #[`item.workingSiteInfo`]="{item}">
        <span v-if="item.workingSiteInfo">{{ item.workingSiteInfo.name }}</span>
      </template>

      <template #[`item.status`]="{item}">
        {{ getStatus(item) }}
      </template>

      <template v-slot:no-data>
        {{ $t('shared.noRecords') }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {
      progressing: false,
      count: 1,
      context: {},
      items: [],
      headers: [
        {text: 'ID', value: 'id'},
        {text: this.$t('shared.firstName'), value: 'firstName'},
        {text: this.$t('shared.lastName'), value: 'lastName'},
        {text: this.$t('shared.mobile'), value: 'mobile'},
        {text: this.$t('shared.email'), value: 'email'},
        {text: this.$t('organizations.organization'), value: 'organizationInfo'},
        {text: this.$t('workingSites.workingSite'), value: 'workingSiteInfo'},
        {text: this.$t('shared.created'), value: 'created'},
        {text: this.$t('shared.status'), value: 'status'},
        {text: this.$t('shared.actions'), value: 'actions', sortable: false}
      ],
      statuses: [
        {text: this.$t('registerRequests.status.PROCESSING'), value: 'PROCESSING'},
        {text: this.$t('registerRequests.status.ACCEPTED'), value: 'ACCEPTED'},
        {text: this.$t('registerRequests.status.REJECTED'), value: 'REJECTED'}
      ],
      search: {
        id: null,
        organizationId: null,
        status: null,
        mobile: null,
        email: null,
        workingSiteId: null,
        created: {min: null, max: null}
      }
    }
  },
  methods: {
    workingSiteSearch(filter) {
      return this.$api.workingSites.getAllInfo(filter)
    },
    organizationSearch(filter) {
      return this.$api.organizations.getAllInfo(filter)
    },
    getStatus(item) {
      const statusInfo = this.statuses.find((i) => i.value === item.status)
      return statusInfo && statusInfo.text
    },
    filter(isCount) {
      const filter = this.$filter()

      if (!isCount) {
        filter['sort[0].column'] = this.context.sortBy[0]
        filter['sort[0].type'] = this.context.sortDesc[0] ? 'ASCENDING' : 'DESCENDING'
        filter.size = this.context.itemsPerPage
        filter.page = this.context.page
      }

      return filter
    },
    getItems(filter) {
      return this.$api.registerRequests.getAll(filter, this.type).then((res) => {
        this.items = res
      })
    },
    getCount(filter) {
      return this.$api.registerRequests.count(filter, this.type).then((res) => {
        this.count = res
      })
    },
    filtering() {
      this.progressing = true
      Promise.all([this.getItems(this.filter()), this.getCount(this.filter(true))])
        .then((res) => {
          this.progressing = false
        })
        .catch((err) => {
          this.$showError(err)
        })
    },
    async accept(item) {
      if (!(await this.$confirm(this.$t('shared.areYouSureToDo')))) return
      const model = {
        status: 'ACCEPTED'
      }
      try {
        this.progressing = true
        await this.$api.registerRequests.update(item.id, model)
        this.filtering()
        this.$showSuccess(this.$t('registerRequests.registerRequestAccepted'))
      } catch (error) {
        this.$showError(error)
      } finally {
        this.progressing = false
      }
    },
    async reject(item) {
      if (!(await this.$confirm(this.$t('shared.areYouSureToDo')))) return
      const model = {
        status: 'REJECTED'
      }
      try {
        this.progressing = true
        await this.$api.registerRequests.update(item.id, model)
        this.filtering()
        this.$showSuccess(this.$t('registerRequests.registerRequestRejected'))
      } catch (error) {
        this.$showError(error)
      } finally {
        this.progressing = false
      }
    },
    async removeItem(item) {
      if (!(await this.$confirm())) return

      try {
        this.progressing = true
        await this.$api.registerRequests.delete(item.id)
        this.filtering()
        this.$showSuccess(this.$t('registerRequests.registerRequestRemoved'))
      } catch (error) {
        this.$showError(error)
      } finally {
        this.progressing = false
      }
    }
  }
}
</script>
